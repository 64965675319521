import React from 'react';

const ArmaaniContact = () => {
    return(
        <div>
            <p>
                This Is Contact Page
            </p>
        </div>
    )
}

export default ArmaaniContact;